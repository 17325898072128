<script setup lang="ts">
    import useCpBem from '~/composables/useCpBem';
    import CpSkeleton from '~/components/cp/skeleton/CpSkeleton/CpSkeleton.vue';

    defineProps<{
      totalCards: number,
      kind: string
    }>();
    const {b} = useCpBem('cp-section-strip-skeleton');
</script>

<template>
    <div :class="b">
        <CpSkeleton v-for="n in totalCards" :key="n" :kind="kind" effect="fade" />
    </div>
</template>

<style scoped lang="scss">
  .cp-section-strip-skeleton {
    display: grid;
    gap: $cp-gutter;
    flex-wrap: wrap;
    grid-template-columns: 1fr 1fr;
  }
</style>
